@import 'node_modules/labcorp-bootstrap/scss/labcorp/required';

/**
 * Be judicious when adding global styles here. 99% of styling should be done
 * within a specific component's stylesheet. In the **VERY** rare event that
 * you need to add global styles here, be sure to scope your styling in such a
 * way that will not inadvertently pollute the global space.
 *
 * For example, you can add your styles inside of a css class selector rather
 * than broadly selecting html elements:
 *
 * ```scss
 * // This is very, very bad
 * button {
 *   background-color: #fff;
 * }
 *
 * // Do this instead
 * .my-css-class-selector {
 *   & button {
 *     background-color: #fff;
 *   }
 * }
 * ```
 */

// Layout Styles
.main-content-wrapper {
  > router-outlet + * {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

// Do not show header while printing
@media print {
  header.fixed-top {
    display: none !important;
  }
}

// Custom Buttons Styles
.btn.btn-text-lg {
  font-size: 1.2rem !important;
}

.btn.btn-link.card-link.small {
  font-size: 14px;
  margin-right: 1rem;
}

.ngb-dp-day.disabled {
  .custom-day {
    color: #b0b8be !important;
  }
}

// Invalid select box border
ui-select.is-invalid .ng-select .ng-select-container {
  border: 1px solid #dc1439 !important;
}

// NG Select Disabled Styles
@import 'node_modules/@ng-select/ng-select/scss/mixins';

ui-select > .ng-select.ng-select-disabled > .ng-select-container {
  color: $input-disabled-color;
  background-color: $input-disabled-bg !important;
}

// Custom Accordion Styles
.accordion.custom-accordion {
  .card {
    .card-header {
      padding: 0;
    }
  }
}

.accordion {
  > .card {
    &.active {
      overflow: visible;
    }
  }

  > .card:first-of-type {
    &.active {
      > .card-header {
        border-top-left-radius: $card-inner-border-radius;
        border-top-right-radius: $card-inner-border-radius;
      }
    }
  }

  > .card:last-of-type {
    &.active {
      .card-body {
        border-bottom-left-radius: $card-inner-border-radius;
        border-bottom-right-radius: $card-inner-border-radius;
      }
    }
  }
}

ngb-datepicker .ngb-dp-day.hidden {
  background: none;
}

ngb-tooltip-window {
  left: 7px !important;
}

//Overriding Bootstrap 5 style to match with bootstrap 4
a {
  text-decoration: none !important;
}

label {
  font-weight: 600;
  margin-bottom: 0.5rem;
}

/* Targeting all form-check-label and form-check-label */
label.form-check-label,
input[type='checkbox'] + label {
  font-weight: 400;
}

.form-group {
  margin-bottom: 1rem;
}

.alert .text {
  padding: 0.75rem 1rem;
  width: 100%;
}

.btn-link:hover {
  text-decoration: underline !important;
}

.address-suggestions {
  background-color: #fff;
  border: solid 1px $gray-500;
  border-top: 0 none;
  border-bottom-left-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
  height: auto;
  max-height: 300px;
  position: absolute;
  z-index: 1;
  margin-right: 20px;
  width: calc(58% - 30px);
  overflow-y: auto;
  overflow-x: hidden;

  ul {
    margin: 0;
    padding-left: 0.5rem;

    li {
      font-size: 14px;
      font-style: normal;
      list-style: none;
      margin-left: 0;
      padding: 0.5rem;

      &:hover {
        background-color: #e3f3ff;
      }
    }
  }
}
